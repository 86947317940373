<template>
  <div class="w-11/12 mx-auto mt-20">
    <img
      v-for="(logo, idx) in clientLogos"
      :key="idx"
      class="mx-auto w-200"
      :class="{ 'mb-25': idx !== clientLogos.length - 1 }"
      :src="logo"
    />
    <div class="mt-20 border rounded-lg border-grey-300">
      <div v-if="hasError || didLoadFail" class="px-40 py-20">
        <h6 class="mb-10">{{ $t('survey.invalid_link') }}</h6>
        <section>
          <p class="mb-10 text-sm text-grey-600">
            {{ $t('survey.invalid_body_1') }}
          </p>
          <p class="text-sm text-grey-600">
            {{ $t('survey.invalid_body_2') }}
          </p>
        </section>
      </div>
      <div v-else-if="surveyJsModel">
        <Survey :survey="surveyJsModel" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import _forIn from 'lodash/forIn'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _omit from 'lodash/omit'
import 'survey-core/defaultV2.min.css'
import api from '@platform-shared/services/api'
import { defaultSurveyTheme } from '@platform-shared/styles/surveyTheme'
import { Model } from 'survey-core'
import { Survey } from 'survey-vue-ui'

export default {
  components: {
    Survey,
  },
  props: {
    memberId: {
      type: String,
      default: '',
    },
    surveyId: {
      type: String,
      default: '',
    },
    responseId: {
      type: String,
      default: '',
    },
    signature: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      hasError: !(
        this.memberId &&
        this.surveyId &&
        this.responseId &&
        this.signature
      ),
      surveyJsModel: null,
      surveyResponseId: 0,
      currentPageNumber: 0,
    }
  },
  computed: {
    ...mapGetters('client', ['loginLogos']),
    ...mapGetters('surveys', ['didLoadFail', 'hasLoaded', 'isLoading']),
    clientLogos() {
      return _map(
        this.loginLogos,
        (logo) => `${this.$settings.S3_ROOT}/${logo}`
      )
    },
  },
  mounted() {
    if (!this.hasLoaded && !this.didLoadFail) {
      const _this = this

      this.getMemberSurveyWithSignature({
        memberId: this.memberId,
        surveyId: this.surveyId,
        responseId: this.responseId,
        signature: this.signature,
      }).then(function(res) {
        const surveyConfigJson = _get(res, 'data.survey.surveyConfig')
        const model = surveyConfigJson && new Model(surveyConfigJson)

        if (model) {
          const currentResponse = _get(res, 'data.response')

          _forIn(
            //  Remove all of the core survey parameters that are required and
            //  iterate on those that remain.
            _omit(_this.$route.query, ['m', 'sid', 'rid', 'sig']),
            (value, key) => {
              //  If the current survey response (as fetched from the DB) DOES
              //  NOT contain a value for the parameter in question, then set
              //  one. This enables survey authors to define variable-based
              //  expressions that can be included in the submitted response
              //  data.
              if (!_get(currentResponse, key)) {
                model.setVariable(key, value)
              }
            }
          )

          //  Themes should probably be a part of the actual survey config rather
          //  than statically defined in app code.
          model.applyTheme(defaultSurveyTheme)

          model.data = _get(res, 'data.response', model.data)
          model.currentPageNo = _get(res, 'data.response.sjsPageNumber', 0)

          model.onComplete.add(_this.onSurveyCompleted)
          model.onCurrentPageChanged.add(_this.onSurveyUpdated)

          //  We could also save after every discrete answer via
          //  model.onValueChanged.add(_this.onSurveyUpdated), but that might be
          //  overkill
        }

        _this.surveyJsModel = model
        _this.surveyResponseId = res.data.id
      })}
  },
  methods: {
    ...mapActions('surveys', ['getMemberSurveyWithSignature']),
    onSurveyCompleted(survey) {
      this.updateMemberSurveyWithSignature({
        memberId: this.memberId,
        surveyId: this.surveyId,
        surveyResponseId: this.surveyResponseId,
        signature: this.signature,
        completed: true,
        surveyResponse: survey.data,
      })
    },
    onSurveyUpdated(survey) {
      if (survey.currentPageNo !== this.currentPageNumber) {
        this.currentPageNumber = survey.currentPageNo

        this.updateMemberSurveyWithSignature({
          memberId: this.memberId,
          surveyId: this.surveyId,
          surveyResponseId: this.surveyResponseId,
          signature: this.signature,
          surveyResponse: {
            ...survey.data,
            sjsPageNumber: survey.currentPageNo,
          },
        })
      }
    },
    updateMemberSurveyWithSignature({
      memberId,
      surveyId,
      surveyResponseId,
      signature,
      completed,
      surveyResponse,
    }) {
      //  We're issuing this API request in a fire-and-forget manner so that
      //  side effects are not introduced into application state. If we update
      //  state with the response, then SurveyJS unnecessarily rerenders the
      //  survey, which can cause issues on the file page (the "completion"
      //  message disappears).
      //
      //  I'm sure a more elegant solution can be implemented, but this works
      //  just fine for now.
      api()
        .url(
          `/v1/${process.env.VUE_APP_DEVICE}/questionnaire/${memberId}/surveys/${surveyId}/responses/${surveyResponseId}?signature=${signature}`
        )
        .put({
          completed,
          surveyResponse,
        })
    },
  },
}
</script>
